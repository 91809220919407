import React from 'react'
import { connectCurrentRefinements } from 'react-instantsearch-dom'
import get from 'lodash/get'
import './currentRefinementList.css'

import {
  fixAttributeLabel,
  filterCurrentRefinements,
  extractAttributeKeyFromRefinement,
} from '../lib/util'

const CurrentRangeRefinement = ({ currentValues, hit, refine, item }) => (
  <div
    className="filterValue"
    onClick={event => {
      event.preventDefault()
      refine(item.value)
    }}
  >
    {hit
      ? get(hit, `attributes.${extractAttributeKeyFromRefinement(item)}.text`)
      : currentValues
          .replace(
            `attributes.${extractAttributeKeyFromRefinement(item)}.value`,
            ''
          )
          .replace('<=', '')}{' '}
    <span className="filterValue-exit">X</span>
  </div>
)

const CurrentRefinements = ({ items, refine, attributes, hit = null }) => (
  <div className="container">
    {items.map(item => {
      const currentValues = item.items
        ? hit
          ? filterCurrentRefinements(get(item, 'items'), hit, item)
          : get(item, 'items')
        : get(item, 'label')

      return (
        <div key={item.label}>
          {currentValues && (
            <div className="attributeContainer">
              {fixAttributeLabel(item, attributes)}:
              {typeof currentValues === 'string' ? (
                <CurrentRangeRefinement
                  currentValues={currentValues}
                  hit={hit}
                  refine={refine}
                  item={item}
                />
              ) : (
                currentValues.map((val, i) => (
                  <div
                    className="filterValue"
                    key={i}
                    onClick={event => {
                      event.preventDefault()
                      refine(val.value)
                    }}
                  >
                    {extractAttributeKeyFromRefinement(item) ===
                      'SHOREHAERTE' && val.label === '0'
                      ? 'keine Angabe '
                      : val.label}
                    <span className="filterValue-exit">X</span>
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      )
    })}
  </div>
)

export default connectCurrentRefinements(CurrentRefinements)
