import React from 'react'
import { Highlight } from 'react-instantsearch-dom'
import get from 'lodash/get'
import Collapsible from 'react-collapsible'
import './hit.css'
import { ReactComponent as DownloadIcon } from '../assets/icons/cloud-arrow-down-solid.svg'
import CurrentRefinementList from './CurrentRefinementList'
import { checkUrl } from '../lib/util'

const headerComponent = (hit, attributes) => (
  <div className="custom-hit-header-container">
    <div>
      <div className="custom-hit-header">
        <span className="custom-hit-header-title">
          <Highlight attribute="name" hit={hit} />
        </span>
        <span className="custom-hit-header-subtitle">
          <Highlight attribute="method" hit={hit} />
        </span>
      </div>
      <div className="custom-hit-category">
        <span className="custom-hit-category-main">
          {get(hit, 'attributes.KATEGORIE.value')}
        </span>
        <span className="custom-hit-category-sub">
          ({get(hit, 'attributes.MATERIALBASIS.value')})
        </span>
        {get(hit, 'dataUrl') && (
          <span className="custom-hit-category-sub">
            {'- '}
            <a
              onClick={event => {
                event.preventDefault()
                window.open(checkUrl(get(hit, 'dataUrl')))
              }}
              href={checkUrl(get(hit, 'dataUrl'))}
              className="dataLink"
              type="button"
            >
              Datenblatt{' '}
              <span>
                <DownloadIcon />
              </span>
            </a>
          </span>
        )}
      </div>
      <CurrentRefinementList attributes={attributes} hit={hit} />
    </div>
    <div className="imgContainer">
      <img
        src={get(hit, 'imageUrl')}
        className="productImg"
        alt={get(hit, 'name')}
      />
    </div>
  </div>
)

export default attributes => ({ hit }) => (
  <Collapsible
    trigger={headerComponent(hit, attributes)}
    triggerTagName="h2"
    className="hit-collapsible"
    openedClassName="hit-collapsible"
  >
    <div className="custom-hit-body">
      {Object.entries(hit.attributes)
        .map(([key, { text }]) => ({
          key,
          value: text,
          name: get(attributes, `${key}.name`),
        }))
        .sort((a, b) => (!a.name ? -1 : a.name.localeCompare(b.name)))
        .map(({ key, value, name }) => (
          <div className="custom-hit-body-attribute" key={key}>
            <span className="custom-hit-body-attribute-name">{name}</span>
            <span className="custom-hit-body-attribute-value">{value}</span>
          </div>
        ))}
    </div>
  </Collapsible>
)
