import React from 'react'

import Search from './components/Search'
import './App.css'
import Logo from './assets/images/rapidobject-logo.png'

import attributes from './config/attributes.json'

const App = () => (
  <div>
    <header className="header">
      <h1 className="header-title">
        <a href="/">Material-Auswahlhilfe (BETA) von Rapidobject</a>
      </h1>
      <img className="logo" src={Logo} alt="rapidobject logo" />
    </header>

    <div className="container">
      <div className="description">
        <p>
          Diese Material-Auswahlhilfe soll Ihnen über verschiedene Filteroptionen die Auswahl eines geeigneten Materials für Ihr Fertigungsvorhaben erleichtern. Es handelt sich dabei um eine Beta-Version. Eine klickbare Materialübernahme für hochgeladene Bauteile ist derzeit noch nicht möglich. Wechseln Sie dazu bitte in <a href="https://upload.rapidobject.com/" target="_blank" rel="noopener noreferrer">unseren 3D-Druck Online Service</a> und gehen Sie dort in die Materialtabelle unter „Material auswählen“. Sollte eine gewünschte Filterkombination (z.B. Oberflächenbeschaffenheit „rau“ mit Farbe „schwarz“) nicht möglich sein, vereinbaren Sie bitte eine individuelle Telefonberatung:{' '}
        </p>
        <ol>
          <li>
            <a
              href="https://www.etermin.net/rapidobjectgmbh/serviceid/171691?noinitscroll=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              für Kunststoffe
            </a>
          </li>
          <li>
            <a
              href="https://www.etermin.net/rapidobjectgmbh/serviceid/171692?noinitscroll=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              für Metalle
            </a>
          </li>
        </ol>
        Darüber hinaus freuen wir uns über jedes Feedback zu diesem Beta-Angebot unter{' '}
        <a href="mailto:martin.suenderhauf@rapidobject.com">
          martin.suenderhauf@rapidobject.com
        </a>
      </div>
      <div className="description">
        <p style={{marginBottom: '0.5rem'}}>Sie haben das geeignete Material gefunden? Dann nutzen Sie unseren Online 3D-Druck Service. Einfach 3D Daten hochladen, Material & Veredelungen auswählen, Sofort-Preis erhalten und direkt bestellen:</p>
        <a href="https://upload.rapidobject.com/" target="_blank" rel="noopener noreferrer" className="ais-ClearRefinements-button" style={{
          fontSize: '1rem',
        }}>Zum Online 3D-Druck Service</a>
      </div>
      {attributes && <Search attributes={attributes} />}
      <div className="copyright">
        <p> &copy; Rapidobject 2021</p>
      </div>
    </div>
  </div>
)

export default App
