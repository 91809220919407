import algoliasearch from 'algoliasearch/lite'

export const indexName = 'Materialmatrix-Standalone'

export const searchClient = algoliasearch(
  'XFCVXMG591',
  'f4db22d94de37c65f5db401559416920'
)

export const index = searchClient.initIndex(indexName)

export const rangeFacetTypes = [
  'UPDOWNRANGE',
  'MINUPDOWN',
  'MAXUPDOWN',
  'UPDOWN',
]
