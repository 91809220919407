import React, { useState } from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'
import classNames from 'classnames'
import uniqBy from 'lodash/uniqBy'
import get from 'lodash/get'

const CustomRefinementList = ({ items, refine, initialValues, attribute }) => {
  const [toggleList, setToggleList] = useState(false)
  const combinedItems = uniqBy([...items, ...initialValues], 'label').map(
    item => {
      const isRefined = get(item, 'isRefined', false)
      const preItemValue = get(item, 'value')
      const value = Array.isArray(preItemValue) ? preItemValue : [preItemValue]

      return {
        ...item,
        isRefined,
        value,
        unavailable: get(item, 'count', 0) === 0,
      }
    }
  )
  
  return (
    <div
      onClick={() => setToggleList(!toggleList)}
      className={`ais-RefinementList  ${combinedItems.length > 10 &&
        'overlay'} ${toggleList && 'is-open'}`}
    >
      <ul className="ais-RefinementList-list">
        {combinedItems.map((item, index) => (
          <li
            key={index}
            className={classNames('ais-RefinementList-item', {
              'ais-RefinementList-item--empty': item.unavailable,
            })}
          >
            <label className="ais-RefinementList-label">
              <input
                type="checkbox"
                checked={item.isRefined}
                onChange={() => refine(item.value)}
                className="ais-RefinementList-checkbox"
                disabled={item.unavailable}
              />
              <span className="ais-RefinementList-labelText">
                {attribute === 'attributes.SHOREHAERTE.value' &&
                item.label === '0'
                  ? 'keine Angabe'
                  : item.label}
              </span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default connectRefinementList(CustomRefinementList)
