import get from 'lodash/get'

const testUrlPrefix = new RegExp('^https?://')
export const checkUrl = url => (testUrlPrefix.test(url) ? url : `http://${url}`)

/**
 * Workaround
 * items.label isn't the label you'd expect
 * (e.g. 'attributes.GEEIGNETFUERFUNKTIONALEBAUTEILE.value' instead of 'Geeignet-für-funktionale-Bauteile')
 * => The RefinementList implementation has to be adjusted TODO!
 *
 * We use item.attribute to get the name from attributes
 */
export const extractAttributeKeyFromRefinement = item => {
  const attr = get(item, 'attribute')
  return attr.slice(attr.indexOf('.') + 1, attr.lastIndexOf('.'))
}

export const fixAttributeLabel = (item, attributes) =>
  get(attributes, `${extractAttributeKeyFromRefinement(item)}.name`)

export const filterCurrentRefinements = (currentValues, hit, item) =>
  currentValues.filter(val =>
    get(
      hit,
      `attributes.${extractAttributeKeyFromRefinement(item)}.value`,
      []
    ).includes(get(val, 'label'))
  )
