import React, { useEffect, useState } from 'react'
import {
  InstantSearch,
  InfiniteHits,
  SearchBox,
  Pagination,
  connectRange,
  Stats,
  PoweredBy,
  ClearRefinements,
  Configure,
} from 'react-instantsearch-dom'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import chunk from 'lodash/chunk'
import Collapsible from 'react-collapsible'
import './search.css'

import Hit from './Hit'
import { searchClient, indexName, rangeFacetTypes, index } from '../config'
import CustomRefinementList from './CustomRefinementList'
import CurrentRefinementList from './CurrentRefinementList'

const getAttributeFacet = ({ key }) => `attributes.${key}.value`

const initialMenuFacetValues = {}

const MinRangeInput = ({ currentRefinement, refine, min, max, rangestep }) => {
  const handleChange = event => {
    const {
      currentTarget: { value },
    } = event
    if (value >= min && value <= max) {
      refine({
        ...currentRefinement,
        min: value,
      })
    }
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
      }}
    >
      <label>
        Mindestens{' '}
        <input
          className="ais-RangeInput-input ais-RangeInput-input--min"
          type="number"
          placeholder="Min"
          min={min}
          max={max}
          step={rangestep}
          onChange={handleChange}
        />
      </label>
    </form>
  )
}

const MaxRangeInput = ({ currentRefinement, refine, min, max, rangestep }) => {
  const handleChange = event => {
    const {
      currentTarget: { value },
    } = event
    if (value >= min && value <= max) {
      refine({
        ...currentRefinement,
        max: value,
      })
    }
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
      }}
    >
      <label>
        Maximum{' '}
        <input
          className="ais-RangeInput-input ais-RangeInput-input--min"
          type="number"
          placeholder="Max"
          min={min}
          max={max}
          step={rangestep}
          onChange={handleChange}
        />
      </label>
    </form>
  )
}
const CustomMaxRangeInput = connectRange(MaxRangeInput)

const CustomMinRangeInput = connectRange(MinRangeInput)

const RenderMinRangeAttributeFilter = ({ attribute }) => (
  <CustomMinRangeInput
    rangestep={attribute.rangestep}
    attribute={getAttributeFacet(attribute)}
  />
)

const RenderMaxRangeAttributeFilter = ({ attribute }) => (
  <CustomMaxRangeInput
    rangestep={attribute.rangestep}
    attribute={getAttributeFacet(attribute)}
  />
)

const RenderMenuAttributeFilter = ({ attribute }) => {
  const facet = getAttributeFacet(attribute)
  return (
    initialMenuFacetValues[facet] && (
      <CustomRefinementList
        limit={99}
        attribute={getAttributeFacet(attribute)}
        facet={facet}
        initialValues={initialMenuFacetValues[facet]}
      />
    )
  )
}

const RenderAttributeFilter = ({ attribute }) => {
  const { type } = attribute
  if (rangeFacetTypes.includes(type)) {
    if (type === 'MAXUPDOWN') {
      return <RenderMaxRangeAttributeFilter attribute={attribute} />
    } else {
      return <RenderMinRangeAttributeFilter attribute={attribute} />
    }
  } else {
    return <RenderMenuAttributeFilter attribute={attribute} />
  }
}

const getDefaultFacetValues = async facet =>
  index.searchForFacetValues(facet, '', {
    maxFacetHits: 99,
  })
const RenderOptions = ({ chunk }) => {
  return chunk.map((attr, key) => (
    <div key={key} className="render_option">
      <h5 className="render_option_heading">
        {attr && attr.name}
        {attr && attr.unit && ` (${attr.unit})`}
      </h5>
      {attr && <RenderAttributeFilter attribute={attr} />}
    </div>
  ))
}
const AttributesTable = ({ attributes, hasShowMore }) => {
  let chunked = chunk(orderBy(attributes, ['order', 'name']), 4)

  chunked.map(parts => {
    while (parts.length < 4) {
      parts.push(null)
    }
    return parts
  })
  const [showMore, setShowMore] = useState(false)
  return (
    <>
      <div
        className={`attributes_container ${hasShowMore &&
          'hide-rest'}  ${showMore && 'is-open'}`}
      >
        {chunked.map((chunk, key) => (
          <RenderOptions key={key} chunk={chunk} />
        ))}
      </div>
      {hasShowMore && (
        <button
          className={`show_more_options ${showMore && 'is-open'}`}
          onClick={() => setShowMore(prev => !prev)}
        >
          <span />
        </button>
      )}
    </>
  )
}

export default ({ attributes }) => {
  const [grid, setGrid] = useState(
    localStorage.getItem('grid') === null
      ? true
      : localStorage.getItem('grid') === 'true'
  )
  const [values, setValues] = useState([])
  const [facets, setFacets] = useState([])
  const handleGrid = () => {
    localStorage.setItem('grid', !grid)
    setGrid(!grid)
  }
  useEffect(() => {
    async function fetchData() {
      const values = Object.values(attributes)
      const facets = values.map(getAttributeFacet)

      await Promise.all(
        facets
          .filter(attr => !rangeFacetTypes.includes(attr.type))
          .map(async facet => {
            initialMenuFacetValues[facet] = []

            await getDefaultFacetValues(facet).then(({ facetHits }) => {
              initialMenuFacetValues[facet].push(
                ...facetHits.map(facet => ({
                  ...facet,
                  label: facet.value,
                  value: facet.value,
                  isRefined: false,
                  count: 0,
                }))
              )
            })
          })
      )

      setValues(values)
      setFacets(facets)
    }
    fetchData()
  }, [attributes])

  const groupedAttributes = groupBy(attributes, 'group')
  const sortedAttributeKeys = Object.keys(groupedAttributes).sort()

  return (
    <InstantSearch searchClient={searchClient} indexName={indexName} refresh>
      <div className="search-panel">
        <div className="search-panel-header">
          <div className="switch-container">
            <label className="switch">
              <input
                name="grid"
                id="grid"
                type="checkbox"
                defaultChecked={grid}
                value={grid}
                onClick={() => handleGrid(!grid)}
              />
              <span className="slider round"></span>
            </label>
            <span>Ergebnisse auf der rechten Seite anzeigen </span>
          </div>
          <ClearRefinements
            translations={{
              reset: 'Filter zurücksetzen',
            }}
          />
        </div>

        <div className={`search-panel__results ${grid ? 'row' : 'column'}`}>
          <Configure facets={facets.map(facet => `searchable(${facet})`)} />

          <div className={`search-panel__filters ${grid ? 'row' : 'column'}`}>
            {values.length > 0 ? (
              sortedAttributeKeys.map(keyName => (
                <Collapsible
                  trigger={keyName.slice(keyName.indexOf(' '))}
                  key={keyName}
                  triggerTagName="h2"
                  className="filter-collapsible"
                  openedClassName="filter-collapsible"
                  open={keyName.includes('Basis-Konfiguration')}
                >
                  <AttributesTable
                    attributes={groupedAttributes[keyName]}
                    hasShowMore={keyName.includes('Basis-Konfiguration')}
                  />
                  {keyName.includes('mechanische Eigenschaften') && (
                    <p>
                      Die hinterlegten Kennwerte stellen Mittelwerte dar und
                      dienen einer ersten Eingrenzung relevanter Materialen. Zum
                      Teil gibt es eine Spanne für Kennwerte, die in den
                      Materialdatenblättern angegeben sind.
                    </p>
                  )}
                </Collapsible>
              ))
            ) : (
              <div id="loading" />
            )}
          </div>
          <div className={`results ${grid ? 'row' : 'column'}`}>
            <CurrentRefinementList attributes={attributes} />

            <ClearRefinements
              translations={{
                reset: 'Filter löschen',
              }}
            />

            <SearchBox
              className="searchbox"
              translations={{
                placeholder: '',
              }}
              searchAsYouType={true}
              showLoadingIndicator={true}
            />

            <Stats
              translations={{
                stats: (nbHits, timeSpentMS) =>
                  `${nbHits} Ergebnis${
                    nbHits > 1 ? 'se' : ''
                  } in ${timeSpentMS}ms`,
              }}
            />

            <InfiniteHits
              hitComponent={Hit(attributes)}
              translations={{
                loadPrevious: 'Vorherige laden',
                loadMore: 'Mehr laden',
              }}
            />

            <div className="pagination">
              <Pagination />
            </div>
          </div>
        </div>
      </div>
      <PoweredBy
        translations={{
          searchBy: 'Suche über',
        }}
      />
    </InstantSearch>
  )
}
